// Welcome to Code in Framer
// Get Started: https://www.framer.com/docs/guides/

import { withCSS } from "framer"
import Example from "https://framer.com/m/framer/Example.js@^1.0.0"
import { useEffect } from "react"

const css = `
    .supporta-funds-raised {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        color: #333333;
    }
    @media only screen and (max-width: 1149px) {
        .supporta-funds-raised {
            font-size: 18px;
            line-height: 20px;
            height: 20px;
        }
    }
    @media only screen and (min-width: 1150px) {
        .supporta-funds-raised {
            font-size: 20px;
            line-height: 22px;
            height: 22px;
        }
    }
`

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Opgehaald_bedrag_collecte(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    useEffect(() => {
        const script = document.createElement("script")

        script.src = "https://cdn.supporta.cc/embed.js"
        script.async = true

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <div>
            <span
                class="supporta-funds-raised"
                data-charity="bm1m"
                data-projects="ia4yp2"
            ></span>

            <style>{css}</style>
        </div>
    )
}
